import React from 'react';
import { Link } from 'react-router';
import { Menu, Icon } from 'semantic-ui-react';

export default class GroupItem extends React.Component {

  render() {
    const { group, onClick, to } = this.props;

    if (!group) return null;

    const params = {};
    if (to) {
      params.as = Link;
      params.to = to;
    }

    return (
      <Menu.Item
        className="groupItem"
        title={group.name}
        data-id={group.id}
        onClick={onClick ? () => onClick(group) : null}
        {...params}
      >
        <div>
          <Icon name="graduation cap" />
          <span>{group.name} </span>
        </div>

      </Menu.Item>
    );
  }
}
