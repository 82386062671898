import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { omit, uniqBy } from 'lodash';
import moment from 'moment';

import Controller from '../../components/Controller';
import Modal from '../../components/Modal';

import ChannelForm from './Form';

import { __ } from '../../i18n';
import * as utils from '../../utils';

const createPicture = entityId => `mutation createPicture {
  createPicture(input: { entityId: ${entityId} }) {
    clientMutationId
  }
}`;

const deleteChannelUser = input => `deleteChannelUser_${input.userId}: deleteUserEntity(input: { userId: ${input.userId}, entityId: ${input.entityId}  }) {
  clientMutationId
}`;

@inject('store', 'client', 'api')
@graphql(gql`query ChannelEditQuery($id: ID!) {
  node(id: $id) @connection(key: "Entity", filter: ["id"]) {
    ... on Entity {
      id: dbId
      fullname
      eid
      seeAll
      description
      disabled
      type
      ntfStartTime
      ntfEndTime
      policy
      scope
      picture {
        uri
        id: dbId
        key
      }
      users {
        nodes {
          id: dbId
          fullname
          phone
          email
          confirmPhone
          confirmEmail
          tags {
            nodes {
              id: dbId
              name
            }
          }
        }
      }
      addresses {
        nodes {
          address
          type
          userId
        }
      }
      roles(limit: 400) {
        nodes {
          id: dbId
          name
          type
        }
      }
    }
  }
}
`, {
  options: ownProps => ({
    variables: {
    id: ownProps.params.entity_id
    }
    })
  })
@graphql(gql`mutation updateChannel($updateEntityMutation: UpdateEntityInput!) {
  updateEntity(input: $updateEntityMutation) {
    clientMutationId
  }
}`, {
  options: {
  refetchQueries: ['OrganizationChannelQuery', 'ChannelEditQuery', 'OrganizationGroupsQuery']
  }
  })
@graphql(gql`
  mutation deletePicture($deletePictureMutation: DeletePictureInput!) {
    deletePicture(input: $deletePictureMutation) {
      clientMutationId
    }
  }`, {
  name: 'deletePicture'
  })
@observer
export default class ChannelEdit extends Controller {
  request = (values) => {
    try {
      const { store, api, data } = this.props;
      let addresses = [];
      const groups = [];
      const picture = values.picture;
      const disabled = values.disabled;
      const visibility = values.visibility || 'public';
      const { selectedUsers, selectedGroups, originalUsers } = values;
      const { entity_id } = this.props.params;
      const getInviteAdresses = originalUsers.filter(address => !address.user);
      const ntfStartTime = values.openTime ? moment(values.openTime, 'HH:mm').format('HH:mm:00') : null;
      const ntfEndTime = values.closeTime ? moment(values.closeTime, 'HH:mm').format('HH:mm:00') : null;
      const originalAddresses = data && data.node && data.node.addresses && data.node.addresses.nodes;
      const addedUsers = selectedUsers.filter(({ user }) => !originalUsers.find(original => (original.user && original.user.id) === (user && user.id)));
      selectedGroups.map(group => groups.push({ id: group.id, name: group.name, type: group.type }));

      const pendingAddresses = originalAddresses.reduce((acc, address) => {
        if (!address.userId) acc.push({ address: address.address, tags: null, type: address.type, invite: false });
        return acc;
      }, []);

      getInviteAdresses.map((entity) => {
        const tags = entity && entity.tags && entity.tags.nodes ? entity.tags.nodes.map(tag => omit(tag, '__typename')) : [];
        if (entity && entity.type === 'EMAIL') addresses.push({ address: entity.address, type: 'EMAIL', tags, invite: false });
        if (entity && entity.type === 'PHONE') addresses.push({ address: entity.address, type: 'PHONE', tags, invite: false });
      });

      // eslint-disable-next-line array-callback-return
      originalUsers.map(({ user }) => {
        const tags = user && user.tags && user.tags.nodes ? user.tags.nodes.map(tag => omit(tag, '__typename')) : [];
        if (user && user.email) addresses.push({ address: user.email, type: 'EMAIL', tags, invite: false });
        if (user && user.phone) addresses.push({ address: user.phone, type: 'PHONE', tags, invite: false });
      });

      // eslint-disable-next-line array-callback-return
      addedUsers.map(({ user }) => {
        const tags = user && user.tags && user.tags.nodes ? user.tags.nodes.map(tag => omit(tag, '__typename')) : [];
        if (user && user.email) addresses.push({ address: user.email, type: 'EMAIL', tags, invite: true });
        if (user && user.phone) addresses.push({ address: user.phone, type: 'PHONE', tags, invite: true });
      });

      addresses = addresses.concat(pendingAddresses);

      const removeDuplicateAddressess = uniqBy(addresses, 'address');
      const removedUsers = originalUsers.filter(o => o.user).filter(originalUser => !selectedUsers.find(({ user }) => (user && user.id) === (originalUser.user && originalUser.user.id)));
      const removedIds = removedUsers.filter(e => e.user).map(item => ({ entityId: entity_id, userId: item.user.id }));
      if (removedIds.length > 0) {
        api.patch('mutation', deleteChannelUser, removedIds).catch((err) => {
          console.error(err);
          this.setState({ loading: false });
        });
      }

      if (picture && !picture.uri) {
        return api.upload('picture', { file: picture, query: createPicture(this.props.params.entity_id) })
          .then(async () => this.updateChannel({ addresses: removeDuplicateAddressess, ntfStartTime, ntfEndTime, values, groups, visibility, disabled }))
          .catch((err) => {
            store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false };
            this.setState({ loading: false });
          });
      } else if (!picture && this.props.data.node.picture && this.props.data.node.picture.id) {
        return this.props.deletePicture({
          variables: { deletePictureMutation: { entityId: this.props.params.entity_id } }
        })
          .then(async () => this.updateChannel({ addresses: removeDuplicateAddressess, ntfStartTime, ntfEndTime, values, groups, visibility, disabled }))
          .catch((err) => {
            store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false };
            this.setState({ loading: false });
          });
      }
      return this.updateChannel({ addresses: removeDuplicateAddressess, ntfStartTime, ntfEndTime, values, groups, visibility, disabled });
    } catch (e) {
      console.log(e);
    }
  }

  updateChannel = ({ addresses, visibility, groups, ntfStartTime, ntfEndTime, values, disabled }) => this.props.mutate({
    variables: {
      updateEntityMutation: {
        id: this.props.params.entity_id,
        fullname: values.fullname,
        eid: values.eid,
        description: values.description,
        policy: values.policy,
        scope: !!values.scope?.entities?.contentApprover ? ['CONTENT_APPROVER'] : [],
        ntfStartTime,
        ntfEndTime,
        disabled,
        addresses,
        groups,
        seeAll: visibility === 'public',
        updateIfExists: false,
        defaultFeatures: undefined
      }
    }
  }).then(() => {
    const messageSnackbar = {
      saved: __('%s was saved', values.fullname),
      disabled: __('%s successfully disabled', values.fullname),
      enabled: __('%s successfully enabled', values.fullname),
    };
    const messageSelect = values.disabled === this.prevDisabled ? 'saved' : values.disabled ? 'disabled' : 'enabled';

    this.props.store.snackbar = { active: true, message: messageSnackbar[messageSelect], success: true };
    this.setState({ loading: false });
    this.props.router.goBack();
  }).catch((err) => {
    this.setState({ loading: false });
    const error = utils.handleError(err.graphQLErrors[0]);
    const errors = {};
    if (err.graphQLErrors[0].message.indexOf('address') > -1) {
      errors.address = error;
    } else {
      errors.fullname = error;
    }
    this.setState({ errors });
    throw err;
  })

  render() {
    const { data } = this.props;

    if ((data.loading && !data.node) || !data.node) return <Modal loading />;

    const { id, fullname, eid, description, picture, seeAll, roles, type, ntfEndTime, ntfStartTime, disabled, policy, scope } = data.node;
    this.prevDisabled = disabled;
    let originalUsers = [];
    const originalUsersConstructor = (data && data.node && data.node.users && data.node.users.nodes) || [];
    originalUsersConstructor.map(user => ['phone', 'email'].map(address => originalUsers.push({ address: user[address], tags: user.tags && user.tags.nodes && user.tags.nodes.map(t => ({ id: t.id, name: t.name })), type: address.toUpperCase(), user })));

    const originalGroups = (roles && roles.nodes) || [];
    const visibility = seeAll ? 'public' : 'custom';
    originalUsers = originalUsers.filter(item => item.address);
    originalUsers = originalUsers.map(i => ({ ...i, original: true }));

    return (
      <Controller
        id="ChannelEdit"
        modal={{
          closeOnRootNodeClick: false,
          fullScreen: this.isMobile(),
          cssTags: this.isMobile() && 'ismobileadd',
          portalHeader: this.isMobile(),
          actions: false
        }}
        modalType="beta"
        edit
        title={__('Edit channel')}
        form={ChannelForm}
        loading={this.state.loading}
        submitButton={{
          text: __('Save'),
          isActionButtom: true
        }}
        cancelButton={{
          text: __('Cancel'),
          isActionButtom: true
        }}
        values={{
          id,
          fullname,
          eid,
          description,
          picture,
          disabled,
          originalUsers,
          originalGroups,
          selectedGroups: originalGroups,
          selectedUsers: originalUsers,
          openTime: ntfStartTime && ntfStartTime.trim() !== '' ? moment(ntfStartTime, 'HH:mm:00').format('HH:mm') : null,
          closeTime: ntfEndTime && ntfEndTime.trim() !== '' ? moment(ntfEndTime, 'HH:mm:00').format('HH:mm') : null,
          visibility,
          type,
          policy,
          scope: utils.parseScopeToForm(scope),
        }}
        submitButtonIcon="check"
        errors={this.state.errors}
        onSubmit={this.onSubmit}
        {...this.props}
      />
    );
  }
}
