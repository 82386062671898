import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { Button, Loader, Menu, Label, Header, Popup, Breadcrumb } from 'semantic-ui-react';

import TableView from '../../components/TableView';
import Form from '../../components/Form';
import Input from '../../components/Input';
import Avatar from '../../components/Avatar';
import Page from '../../components/Page';

import RoleAdd from '../Role/Add';
import RoleDelete from '../Role/Delete';

import { __ } from '../../i18n';
import * as utils from '../../utils';

const typeOptions = organizationType => [
  { key: 0, text: utils.checkIfItIsClientOrSchool(organizationType) === 1 ? __('Student') : __('Client'), value: 'STUDENT', 'data-value': 'student' },
  { key: 1, text: __('Staff'), value: 'STAFF', 'data-value': 'staff' },
  { key: 2, text: __('Admin'), value: 'ADMIN', 'data-value': 'admin' }
];

const entityTypes = organizationType => ({
  STUDENT: { name: utils.checkIfItIsClientOrSchool(organizationType) === 1 ? __('Student') : __('Client'), color: 'grey' },
  STAFF: { name: __('Staff'), color: 'brown' },
  ADMIN: { name: __('Admin'), color: 'black' }
});

const PAGE_SIZE = 40;

@inject('store')
@graphql(gql`query GroupNodeQuery ($id: ID!, $limit: Int, $offset: Int, $search: String, $type: [EntityType]) {
    node(id: $id) @connection(key: "Group", filter: ["id"]) {
      ... on Group {
        id: dbId
        name
        organization {
          id: dbId
          type
        }
        entities(limit: $limit, offset: $offset, search: $search, type: $type) {
          nodes {
            fullname
            id: dbId
            description
            type
            picture {
              uri
              id: dbId
              key
            }
          }
          pageInfo {
            hasPreviousPage
            hasNextPage
          }
        }
      }
    }
  }
`, {
  options: ownProps => ({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      id: ownProps.params.group_id,
      limit: PAGE_SIZE,
      offset: 0,
      search: ownProps.location.query.search || '',
      type: (ownProps.location.query.type && [ownProps.location.query.type]) || []
    }
  })
})
@observer
export default class GroupNode extends Page {
  openRoleAdd = () => {
    this.props.store.appends.push(
      <RoleAdd
        {...this.props}
        onClose={() => this.props.store.appends.pop()}
      />
    );
  }

  openRoleDelete = (entity_id) => {
    const { params } = this.props;
    this.props.store.appends.push(
      <RoleDelete
        {...this.props}
        params={{ ...params, entity_id }}
        onClose={() => this.props.store.appends.pop()}
        onCancel={() => this.props.store.appends.pop()}
      />
    );
  }

  renderEntityFullname = ({ to, fullname }) => {
    if (this.props.store.currentEntity.type !== 'ADMIN') return <span>{fullname}</span>;

    return <Link className="bold defaultClspColor" to={to}>{fullname}</Link>;
  }

  renderColumns = () => [
    <Form.Field
      control={Input}
      wait
      name="search"
      icon="search"
      placeholder={__('Search...')}
      onChange={(e, { value }) => this.onUniqueParameterChange('search', value)}
    />,
    <Form.Dropdown
      fluid
      multiple
      selection
      data-name="type"
      placeholder={__('Type')}
      style={{ paddingRight: '3.5em' }}
      value={this.props.location.query.type ? [this.props.location.query.type] : []}
      options={typeOptions(this.props.data.node.organization.type)}
      onChange={(e, { value }) => this.onUniqueParameterChange('type', value, 'array')}
    />,
    null
  ]

  renderRowCells = entity => [
    <Header as="h5" className="entityItem" title={entity.fullname} data-id={entity.id}>
      <Avatar avatar spaced="right" src={entity.picture && entity.picture.uri} alt={entity.fullname || ''} style={{ height: '2.5em' }} />
      <Header.Content>
        {
          entity.fullname ?
            (
              entity.fullname.length > 18 ?
                <Popup
                  trigger={this.renderEntityFullname({ to: `/entities/${entity.id}`, fullname: entity.fullname.substring(0, 18).trim() + '...' })}
                  content={entity.fullname}
                />
                :
                this.renderEntityFullname({ to: `/entities/${entity.id}`, fullname: entity.fullname })
            )
            :
            null
        }
        <Header.Subheader>
          {
            entity.description && entity.description.length > 18 ?
              <Popup
                trigger={<span>{entity.description.substring(0, 18).trim()} ...</span>}
                content={entity.description}
              />
              :
              (entity.description)
          }
        </Header.Subheader>
      </Header.Content>
    </Header>,
    <Label
      data-name="entityType"
      data-value={entity.type}
      color={entityTypes(this.props.data.node.organization.type)[entity.type].color}
      horizontal
      size="tiny"
      className="uppercase"
    >
      {entityTypes(this.props.data.node.organization.type)[entity.type].name}
    </Label>,
    !(this.props.store.currentEntity.type === 'ADMIN' || (this.props.store.currentEntity.organization && this.props.store.currentEntity.organization.permissions && (this.props.store.currentEntity.organization.permissions.hasAdmin || this.props.store.currentEntity.organization.permissions.entityScopes.includes('CREATE_UPDATE_GROUP')))) ? null :
      (
        <Popup
          trigger={<Button
            basic
            data-action="open-role-delete"
            data-params={entity.id}
            icon="trash"
            onClick={() => this.openRoleDelete(entity.id)}
          />}
          content={__('Remove person from group')}
        />
      )
  ]

  render() {
    const { data, params, store } = this.props;

    if ((data.loading && !data.node) || !data.node) return <Loader active inline="centered" />;

    const { entities, name } = data.node;
    const { nodes, pageInfo } = entities;

    return (
      <div id="GroupNode">
        {
          (store.currentEntity.type === 'ADMIN' || store.currentEntity.organization.permissions.hasAdmin || store.currentEntity.organization.permissions.entityScopes.includes('CREATE_UPDATE_GROUP')) &&
          (
            <Menu compact floated="right" style={{ marginTop: '-1em' }}>
              <Menu.Item
                data-action="open-role-add"
                content={__('Add people')}
                icon="plus"
                onClick={() => this.openRoleAdd()}
              />
            </Menu>
          )
        }
        <Breadcrumb>
          <Breadcrumb.Section
            link
            as={Link}
            to={`/organizations/${params.organization_id}/groups`}
          >
            {__('Groups')}
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section active>{(name.length > 80) ? (name.substring(0, 80) + '...') : name}</Breadcrumb.Section>
        </Breadcrumb>
        <TableView
          renderColumns={this.renderColumns()}
          renderRowCells={this.renderRowCells}
          source={nodes}
          pagination={{
            graphql: true,
            hasNextPage: pageInfo && pageInfo.hasNextPage,
            loading: data.loading
          }}
          onLoadMore={() => this.loadMore('entities')}
        />
        {this.props.children}
      </div>
    );
  }
}
