import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { omit, uniqBy } from 'lodash';
import moment from 'moment';

import Controller from '../../components/Controller';

import ChannelForm from './Form';

import { __ } from '../../i18n';
import * as utils from '../../utils';


const createPicture = entityId => `mutation createPicture {
  createPicture(input: { entityId: ${entityId} }) {
    clientMutationId
  }
}`;

@inject('store', 'client', 'api')
@graphql(gql`mutation createEntity($createEntityMutation: CreateEntityInput!) {
  createEntity(input: $createEntityMutation) {
    clientMutationId
    entity {
      id: dbId
    }
  }
}`)

@graphql(gql`mutation updateChannel($updateEntityMutation: UpdateEntityInput!) {
  updateEntity(input: $updateEntityMutation) {
    clientMutationId
  }
}`, {
  name: 'updateChannel',
  options: {
    refetchQueries: ['OrganizationChannelQuery', 'OrganizationGroupsQuery']
  }
})

@observer
export default class ChannelAdd extends Controller {
  static fetchData({ store }) {
    store.app.title = __('Add channel');
  }

  request = (values) => {
    const { store, api } = this.props;
    const { location } = this.props;
    const query = { ...location.query };
    const entityValues = omit(values, 'picture', Object.keys(query));
    const selectedUsers = values.selectedUsers;
    const selectedGroups = values.selectedGroups;
    const picture = values.picture;
    const visibility = entityValues.visibility || 'public';
    const ntfStartTime = values.openTime ? moment(values.openTime, 'HH:mm').format('HH:mm:00') : null;
    const ntfEndTime = values.closeTime ? moment(values.closeTime, 'HH:mm').format('HH:mm:00') : null;
    const addresses = [];
    const groups = [];
    const isApprover = !!values.scope?.entities?.contentApprover;
    // eslint-disable-next-line array-callback-return
    selectedUsers.map((user) => {
      addresses.push({ address: user.address, type: user.type, tags: (user.tags && user.tags.nodes && user.tags.nodes.map(t => ({ id: t.id, name: t.name }))) || [], invite: true });
    });

    selectedGroups.map(group => groups.push({ id: group.id, name: group.name, type: null }));
    const removeDuplicateAddressess = uniqBy(addresses, 'address');
    return this.props.mutate({
      variables: {
        createEntityMutation: {
          organizationId: this.props.params.organization_id,
          fullname: entityValues.fullname,
          eid: entityValues.eid,
          type: 'STAFF',
          description: entityValues.description,
          invisible: false,
          seeAll: visibility === 'public',
          ntfStartTime,
          ntfEndTime,
          addresses: removeDuplicateAddressess,
          groups: visibility === 'public' ? [] : groups,
          defaultFeatures: ['MESSAGE'],
          updateIfExists: false,
          policy: values.policy,
          scope: isApprover ? ['CONTENT_APPROVER'] : [],
        }
      }
    }).then(async (res) => {
      if (picture) {
        await api.upload('picture', {
          file: picture,
          query: createPicture(res.data.createEntity.entity.id)
        })
          .then(async () => this.updateChannel({ values: entityValues, ntfStartTime, ntfEndTime, id: res.data.createEntity.entity.id }))
          .catch((err) => {
            console.log(err);
            store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false };
          });
      } else {
        return this.updateChannel({ values: entityValues, id: res.data.createEntity.entity.id });
      }
    });
  };

  updateChannel = ({ values, ntfStartTime, ntfEndTime, id }) => this.props.updateChannel({
    variables: {
      updateEntityMutation: {
        id,
        ntfStartTime,
        ntfEndTime,
        fullname: values.fullname
      }
    }
  }).then(() => {
    this.props.store.snackbar = { active: true, message: __('%s was added', values.fullname), success: true };
    this.props.router.push(`/organizations/${this.props.params.organization_id}/channels`);
  }).catch((err) => {
    this.setState({ errors: { fullname: utils.handleError(err.graphQLErrors[0]) } });
  });


  render() {
    return (
      <Controller
        id="ChannelAdd"
        modal={{
          closeOnRootNodeClick: false,
          fullScreen: this.isMobile(),
          cssTags: this.isMobile() && 'ismobileadd',
          portalHeader: this.isMobile(),
          actions: false
        }}
        modalType={'beta'}
        add
        title={this.isMobile() ? null : __('Add channel')}
        form={ChannelForm}
        loading={this.state.loading}
        submitButton={{
          text: __('Add'),
          isActionButtom: true
        }}
        cancelButton={{
          text: __('Cancel'),
          isActionButtom: true
        }}
        submitButtonIcon="check"
        errors={this.state.errors}
        onSubmit={this.onSubmit}
        {...this.props}
      />
    );
  }
}
