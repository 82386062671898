import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Controller from '../../components/Controller';
import { __ } from '../../i18n';

@graphql(gql`mutation deleteGroup($deleteGroupMutation: DeleteGroupInput!) {
  deleteGroup(input: $deleteGroupMutation) {
    clientMutationId
  }
}`, {
  options: {
  refetchQueries: ['OrganizationGroupsQuery']
  }
  })
@inject('store') @observer
export default class GroupDelete extends Controller {
  request = () => {
    this.setState({ loading: true });

    this.props.mutate({
      variables: {
        deleteGroupMutation: {
          id: this.props.params.group_id
        }
      }
    }).then(() => {
      this.props.store.snackbar = { active: true, message: __('Group was deleted'), success: true };
      this.props.router.push(`/organizations/${this.props.params.organization_id}/groups`);
      this.setState({ loading: false });
    }).catch((err) => {
      console.log(err);
      this.setState({ loading: false });
    });
  }

  render() {
    return (
      <Controller
        id="GroupDelete"
        delete
        object={__('group')}
        onSubmit={this.onSubmit}
        loading={this.state.loading}
        {...this.props}
      />
    );
  }
}
