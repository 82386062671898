import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import imageToBase64 from 'image-to-base64/browser';
import { inject, observer } from 'mobx-react';
import { uniqBy } from 'lodash';
import moment from 'moment';

import Form from '../../components/ui/Form';
import PictureInput from '../../components/ui/PictureInput';
import SelectUser from '../../components/ui/SelectUser';
import SelectGroup from '../../components/ui/SelectGroup';
import BetaModal from '../../components/ui/Modal';
import Button from '../../components/ui/Button';
import { __ } from '../../i18n';
import { asset, b64toBlob, hasEntityInCurrentOrganization } from '../../utils';
import { sendGoogleAnalyticsEvent } from '../../lib/analytics';
import SelectPermissions from '../../components/ui/SelectPermissions';

const styles = {
  label: {
    fontSize: '1.143rem',
    fontWeight: 400
  },
  subTitleText: {
    fontWeight: 'bold',
    fontSize: '1.286rem',
    color: '#000000'
  },
  subTitle: {
    marginBottom: '1.25rem'
  },
  block: {
    boxShadow: '-1px 0px 0px rgba(0, 0, 0, 0.1)',
    height: '2.5rem',
    margin: '2rem 0',
    display: 'flex',
    alignItems: 'center'
  },
  manage: {
    marginBottom: '1.75rem'
  }
};

const acceptedTimes = {
  hour: ['07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18',
    '19', '20', '21', '22', '23', '00', '01', '02', '03', '04', '05', '06'],
  minute: ['00', '15', '30', '45']
};

const timeOptions = acceptedTimes.hour
  .reduce((acc, hour) => {
    const newAcc = [...acc];
    acceptedTimes.minute.forEach(minute => newAcc.push(hour + ':' + minute));
    return newAcc;
  }, ['--:--'])
  .map(v => ({ text: v, value: v }));

const HandleDisableChannel = props => (
  <BetaModal
    id="InfoModal"
    onClose={props.onClose}
    actions={[
      <Button
        floated="left"
        transparent
        data-action="cancel"
        round
        text={__('Cancel')}
        onClick={props.onCancel}
      />,
      <Button
        data-action="ok"
        round
        text={__('Confirm')}
        onClick={props.onClick}
      />,
    ]}
    header={props.disabled ? __('Disable channel') : __('Enable channel')}
    content={
      <div>
        <span style={{ fontSize: '1.143rem', fontWeight: 400 }}>
          {props.disabled ? __('Are you sure you want to disable this channel?') : __('Are you sure you want to enable this channel?')}
        </span>
      </div>
    }
  />
);

@inject('store')
@observer
export default class ChannelForm extends Form {
  constructor(props) {
    super(props);
    this.disabled = props.values.disabled;
  }

  defaultValues = {
    fullname: '',
    eid: '',
    description: '',
    picture: null,
    openTime: null,
    closeTime: null,
    selectedUsers: [],
    selectedGroups: [],
    visibility: '',
    scope: {
      messages: {
        read: false,
        delete: false
      },
      reports: {
        read: false,
        createUpdate: false,
        delete: false
      },
      entities: {
        read: false,
        createUpdate: false,
        delete: false,
        contentApprover: false,
      },
      dashboard: {
        read: false
      },
      integration: {
        read: false
      },
      payments: {
        read: false
      },
      accounts: {
        read: false,
        createUpdate: false,
        delete: false
      },
      organization: {
        update: false
      },
      forms: {
        read: false,
        createUpdate: false,
        delete: false
      },
      links: {
        read: false,
        createUpdate: false,
        delete: false
      },
      policy: [],
    }
  }

  rules = {
    fullname: ['required', values => this.validateContent('fullname', values)],
    selectedUsers: [values => this.validateContent('selectedUsers', values)],
    selectedGroups: [values => this.validateContent('selectedGroups', values)],
    openTime: [values => this.validateContent('openTime', values)],
    closeTime: [values => this.validateContent('closeTime', values)]
  }

  validateContent = (param, values) => {
    let message = null;
    // Channel name
    if (param === 'fullname' && values.trim().length === 0) {
      message = __('Fullname cannot be empty');
    }

    // User
    if (param == 'selectedUsers' && values.length <= 0) {
      message = __('Please select at least one user');
    }

    // Groups
    if (param == 'selectedGroups' && this.state.values.visibility === 'custom' && values.length <= 0) {
      message = __('Custom visibility requires at least one group.');
    }

    // Open Time
    if (param === 'openTime') {
      if ((!values || values.trim() !== '') && this.state.values.closeTime == null) {
        message = __('Select a valid time range');
      }

      if (moment(this.state.values.openTime, 'HH:mm').diff(moment(this.state.values.closeTime, 'HH:mm'), 'minutes') >= 0) {
        message = __('Select a valid time range');
      }
    }
    // Close Time
    if (param === 'closeTime') {
      if ((!values || values.trim() === '') && this.state.value.openTime == null) {
        message = __('Select a valid time range');
      }

      if (this.state.values.openTime === null && this.state.values.closeTime !== null) {
        message = __('Select a valid time range');
      }

      if (moment(this.state.values.openTime, 'HH:mm').diff(moment(this.state.values.closeTime, 'HH:mm'), 'minutes') >= 0) {
        message = __('Select a valid time range');
      }
    }

    if (message !== null) throw new Error(message);
  };

  setDefaultPicture = (iconName) => {
    imageToBase64(asset(`/icons/${iconName}.png`)).then((base64) => {
      const contentType = 'image/png';
      const blob = b64toBlob(base64, contentType);
      this.setValue('picture', blob);
    }).catch((error) => {
      console.error(error);
    });
  }

  renderStatus = (values, edit, disabled) => {
    if (disabled) return null;

    const status = edit ? (<div style={styles.section}>
      <div style={styles.subTitle}>
        <span style={styles.subTitleText}>{__('Status')}</span>
      </div>
      <Form.Switch
        name="status"
        type="status"
        color={'#084FFF'}
        options={[{ value: false, text: __('Habilitado') }, { value: true, text: __('Desabilitado') }]}
        checked={values.disabled}
        onChange={(e, d) => this.setState({ values: { ...values, disabled: d.value } })}
      />
    </div>)
      : null;

    return status;
  }

  renderApprovalMessagesSection = () => {
    const { store } = this.props;
    const { values } = this.state;
    const hasMessageApproval = store.currentOrganization && store.currentOrganization.unleashStatus && store.currentOrganization.unleashStatus.approve_message &&
      store.currentOrganization.plan === 'PREMIUM';

    if (hasMessageApproval)
      return (
        <div style={styles.manage}>
          <div style={styles.subTitle}>
            <span style={styles.subTitleText}>{__('Manage Permissions')}</span>
          </div>

          <SelectPermissions
            listTitle={__('This channel will')}
            entity={values}
            messageApproval
            isChannel
            scope={this.state.values.scope}
            policy={this.state.values.policy}
            onSubmit={((scope, policy) => {
              this.setValue('scope', scope)
              this.setValue('policy', policy)
            })}
            radioList={[
              { value: 'notApprove', label: __('Send messages without going through approval') },
              { value: 'approveAndReprove', label: __('Approve or not approve messages sent by others') },
              { value: 'submitToApprove', label: __('Submit messages for approval before sending') },
            ]}
          />
        </div>
      )
  }

  render() {
    const { values, errors } = this.state;

    const { store, cancelButton, submitButton, submitButtonIcon, loading, edit } = this.props;
    const errorsModal = {
      message: __('Please name the channel and assign the responsible. If you choose custom visibility, select at least one group. If you have set a time range for hours of service, make sure it is correct.')
    };
    const organization = store.currentOrganization || {};
    const actionButtonsLeft = [<Button
      data-action="cancel"
      round
      transparent
      floated="left"
      text={cancelButton.text}
      disabled={loading}
      onClick={() => (this.props.onClose ? this.props.onClose : this.props.router.goBack())}
    />];
    const actionButtonsRight = [<Button
      data-action="submit"
      round
      icon={submitButtonIcon && { name: submitButtonIcon }}
      text={submitButton.text}
      loading={loading}
      onClick={(e, event) => (values.disabled !== this.disabled ?
        this.props.store.appends.push(
          <HandleDisableChannel
            disabled={values.disabled}
            onClose={() => this.props.store.appends.pop()}
            onClick={() => {
              this.handleSubmit(e, event, { errorsModal });
              sendGoogleAnalyticsEvent({
                name: 'Entity Status Menu',
                category: `Status: ${values.disabled ? 'Disabled' : 'Enabled'}`,
                label: `OrganizationID: ${organization.id}`,
              }, { store });
              this.props.store.appends.pop();
            }}
            onCancel={() => this.props.store.appends.pop()}
          />
        )
        : this.handleSubmit(e, event, { errorsModal }))
      }
    />];
    return (
      <Form
        id="ChannelForm"
        {...this.props}
        onSubmit={(e, event) => this.handleSubmit(e, event, { errorsModal })}
        paddingHeader={this.isMobile()}
        actionButtonsLeft={!this.isMobile() ? actionButtonsLeft : null}
        actionButtonsRight={!this.isMobile() ? actionButtonsRight : null}
        header={this.isMobile() ? {
          title: '',
          onClose: () => (this.props.onClose ? this.props.onClose : this.props.router.goBack()),
          invertCloseButton: true,
          headerItem: <Button
            data-action="submit"
            round
            loading={loading}
            text={submitButton.text}
            onClick={(e, event) => this.handleSubmit(e, event, { errorsModal })}
            style={{ height: '40px', width: '100px', justifyContent: 'center', alignItems: 'center', display: 'flex' }}
          />
        } : null}

      >
        {
          this.renderStatus(values, edit, !this.disabled)
        }
        <div style={{ marginBottom: '1rem' }}>
          <div style={styles.subTitle}>
            <span style={styles.subTitleText}>{__('Details')}</span>
          </div>
          <div style={{ display: 'flex' }}>
            <Form.Input
              placeholder={__('Ex: Secretary')}
              style={{ flex: 11, marginRight: '8px' }}
              label={__('Channel`s name')}
              value={values.fullname}
              onChange={e => this.onTextInputChange(e, { name: 'fullname', value: e.target.value, maxDigits: 255 })}
              autoFocus
              maxLength={255}
            />
            <Form.Input
              style={{ flex: 5 }}
              label={__('External ID')}
              placeholder={__('Optional')}
              name="eid"
              value={values.eid}
              onChange={e =>
                this.onTextInputChange(e, { name: 'eid', value: e.target.value, maxDigits: 255 })
              }
            />
          </div>
          {errors && errors.fullname && errors.fullname !== 'Error' &&
            <div className="error-warning-red" >
              <Icon data-value="error" name="exclamation circle" />
              {errors.fullname}
            </div>
          }
          <Form.TextArea
            type="textarea"
            label={__('Description')}
            cssTags={this.isMobile() ? 'big-height' : ''}
            value={values.description}
            error={errors && errors.description && errors.description !== 'Error' ? errors.description : null}
            onChange={e => this.onTextInputChange(e, { name: 'description', value: e.target.value, maxDigits: 255 })}
            placeholder={__('Write a description, it`s optional')}
          />
        </div>

        {(((values.picture && values.picture.id && values.picture.uri) || values.picture instanceof Blob) || hasEntityInCurrentOrganization(store, ['ADMIN']) || store.currentUser.isMaster || (store.currentEntity && store.currentEntity.id === values.id)) &&
          <div style={{ marginBottom: '1rem' }}>
            <span style={styles.label}>{(hasEntityInCurrentOrganization(store, ['ADMIN']) || store.currentUser.isMaster) ? __('Add a picture or choose one bolow:') : __('Appearance')}</span>
            <div style={{ marginTop: '1rem' }}>
              <PictureInput
                entityId={values.id}
                hideEdit={!(hasEntityInCurrentOrganization(store, ['ADMIN']) || store.currentUser.isMaster || (store.currentEntity && store.currentEntity.id === values.id))}
                picture={((values.picture && values.picture.id && values.picture.uri) || values.picture instanceof Blob) ? values.picture : null}
                fullname={values.fullname}
                icons={[
                  { name: 'graduation cap', backgroundColor: '#016EEA', onClick: () => this.setDefaultPicture('graduation-cap') },
                  { name: 'calculator', backgroundColor: '#E75353', onClick: () => this.setDefaultPicture('calculator-icon') },
                  { name: 'user tie', backgroundColor: '#108E82', onClick: () => this.setDefaultPicture('user-tie') }
                ]}
                onSubmit={picture => this.setValue('picture', picture)}
                onDelete={() => this.setValue('picture', null)}
              />
            </div>
          </div>
        }

        <div style={styles.subTitle}>
          <span style={styles.subTitleText}>
            {__('Office Hours')}
            <Popup
              trigger={<Icon style={{ opacity: '.6', marginLeft: '5px' }} name="info circle" color="grey" />}
              content={
                <div
                  onMouseEnter={() => this.setState({ keepPopupOpen: true })}
                  onMouseLeave={() => this.setState({ isPopupOpen: false, keepPopupOpen: false })}
                >
                  <span>{__('Select the service hours to be displayed. See how it works ')}</span>
                  <a
                    href="https://ajuda.classapp.com.br/hc/pt-br/articles/4407625235995"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ cursor: 'pointer' }}
                  >
                    {__('clicking here.')}
                  </a>
                </div>
              }
              hideOnScroll
              basic
              on="hover"
              mouseLeaveDelay={1000}
              open={this.state.isPopupOpen}
              onOpen={() => this.setState({ isPopupOpen: true })}
              onClose={() => {
                if (this.state.isPopupOpen && !this.state.keepPopupOpen) this.setState({ isPopupOpen: false });
              }}
            />
          </span>
        </div>
        {/* <span style={{ ...styles.label, marginBottom: '1rem' }}>{__('Office Hours')}</span> */}
        <Form.Group
          widths="4"
          style={{
            display: 'flex',
            alignItems: 'center',
            margin: '0px',
            marginTop: '1rem'
          }}
        >
          <span style={{ marginBottom: '24px' }}>{__('from (time)')}</span>
          <Form.Dropdown
            className={'form-dropdown'}
            style={{ marginBottom: '0' }}
            name="open"
            icon={'chevron down'}
            compact
            fluid
            selection
            placeholder="--:--"
            error={Boolean(errors.openTime || errors.closeTime)}
            value={this.state.values.openTime}
            options={timeOptions}
            onChange={(e, { value }) => {
              if (value === '--:--') this.setValue('openTime', null);
              else this.setValue('openTime', value);
            }}
          />
          <span style={{ marginBottom: '24px' }}>{__('to (time)')}</span>
          <Form.Dropdown
            className={'form-dropdown'}
            name="close"
            icon={'chevron down'}
            compact
            fluid
            selection
            placeholder="--:--"
            value={this.state.values.closeTime}
            error={Boolean(errors.openTime || errors.closeTime)}
            options={timeOptions}
            onChange={(e, { value }) => {
              if (value === '--:--') this.setValue('closeTime', null);
              else this.setValue('closeTime', value);
            }}
          />
        </Form.Group>
        {errors && (errors.openTime || errors.closeTime) &&
          <div className="error-warning-red" style={{ marginTop: '-10px' }}>
            <Icon data-value="error" name="exclamation circle" />
            {(errors.openTime || errors.closeTime)}
          </div>
        }

        <div style={{ marginBottom: '1rem' }}>
          <div style={styles.subTitle}>
            <span style={styles.subTitleText}>{__('Responsible')}</span>
          </div>
          <SelectUser
            selectedItems={values.selectedUsers}
            error={(errors && errors.selectedUsers && errors.selectedUsers !== 'Error') ? errors.selectedUsers : null}
            onSubmit={selectedUsers => this.setValue('selectedUsers', uniqBy(selectedUsers, 'address'))}
            onDelete={newSelectedUsers => this.setValue('selectedUsers', newSelectedUsers)}
            currentEntityId={this.props.values && this.props.values.id}
          />
        </div>

        <div style={{ marginBottom: '1rem' }}>
          <div style={styles.subTitle}>
            <span style={styles.subTitleText}>{__('Visibility')}</span>
          </div>
          <SelectGroup
            selectedItems={values.selectedGroups}
            error={((errors && errors.selectedGroups && errors.selectedGroups !== 'Error')) ? errors.selectedGroups : null}
            visibility={values.visibility || 'public'}
            disabled={values.type && values.type === 'ADMIN'}
            visibilityFields
            onChange={visibility => this.setValue('visibility', visibility)}
            onSubmit={(selectedGroups => this.setValue('selectedGroups', selectedGroups))}
            onDelete={newSelectedGroups => this.setValue('selectedGroups', newSelectedGroups)}
          />
        </div>
        {
          this.renderStatus(values, edit, this.disabled)
        }

        {this.renderApprovalMessagesSection()}
      </Form>
    );
  }
}
